<div class="dropdown-collection">
  <button class="dropdown-button" (click)="toggleDropdown()" (clickOutside)="closeDropdown()">
    <span *ngIf="currentValue" class="dropdown-value">{{currentValue.name}}</span>
    <span *ngIf="!currentValue" class="dropdown-placeholder">Please select</span>
  </button>
  <ul class="dropdown-list" role="listbox" aria-expanded="false" [ngClass]="{'dropdown-open' : dropdownOpen}">
    <li *ngFor="let o of options"
      [ngClass]="{'selected' : currentValue === o}"
      tabindex="0"
      (click)="select(o)"
      role="option"
      [innerHTML]="o.name"
    ></li>
  </ul>
</div>