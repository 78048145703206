<div class="register-form">
  <form class="form" [formGroup]="form">

    <!-- cardId -->
    <!-- <div class="form-group cardId">
      <label>Card ID</label>
      <input
        type="text"
        formControlName="cardId"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.cardId.errors }"
      />
      <div *ngIf="submitted && f.cardId.errors" class="invalid-feedback">
        <div *ngIf="f.cardId.errors.required">Card Id is required</div>
        <div *ngIf="f.cardId.errors.minlength || f.cardId.errors.maxlength">Card Id should be 36 characters long</div>
      </div>
    </div> -->

    <!-- RRN -->
    <div class="form-group rrn">
      <label>RRN</label>
      <input
        type="text"
        formControlName="rrn"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.rrn.errors }"
      />
      <div *ngIf="submitted && f.rrn.errors" class="invalid-feedback">
        <div *ngIf="f.rrn.errors.required">RRN is required</div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="form-group submit">
      <label class="hidden">hidden</label>
      <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
    </div>
  </form>

  <table class="response-table" *ngIf="apiHasBeenHit && response && response['responseCode']">
    <tr class="header">
      <th class="name">Parameter Name</th>
      <th class="value">Parameter Value</th>
    </tr>
    <tr *ngFor="let key of keysToDisplay" class="{{key}}">
      <td>{{ParameterNameMap[key]}}</td>
      <td *ngIf="key!='responseCode'">{{response[key]}}</td>
      <ng-container *ngIf="key==='responseCode'">
        <td *ngIf="response[key]==='00'" class="success">Success</td>
        <td *ngIf="response[key]==='05'" class="failure">Failure</td>
      </ng-container>
    </tr>
  </table>

  <div *ngIf="apiHasBeenHit && (!response || (response && !response['responseCode']))" class="no-data">
    <img [src]="images.ReversalNoData" alt="no data avaiable">
  </div>

</div>