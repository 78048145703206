export const Constants = {

  ParameterNameMap: {
    // "responseCode": "Response Code",
  },

  kycApprovalOptions: [
    {
      name: "Success",
      value: "SUCCESS"
    },
    {
      name: "Failure",
      value: "FAILURE"
    },
    {
      name: "Pending",
      value: "PENDING"
    },
    {
      name: "Unsuccessful",
      value: "UNSUCCESSFUL"
    }
  ],
}
