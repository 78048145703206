export const Constants = {

  ParameterNameMap: {
    "status": "Status",
    "rrn": "RRN",
  },

  paymentChannelOptions: [
    {
      name: '',
      value: null
    },
    {
      name: "ATM",
      value: "ATM01"
    },
    {
      name: "POS",
      value: "POS01"
    },
    {
      name: "ECOM",
      value: "ECOM01"
    },
  ],

  merchantNameOptions: [
    {
      name: '',
      mcc: null,
      mid: null
    },
    {
      name: 'India Post',
      mcc: '9402',
      mid: 'P1572'
    },
    {
      name: 'Virgin Atlantic',
      mcc: '3144',
      mid: 'A1265'
    },
    {
      name: 'L&S Attorneys',
      mcc: '8111',
      mid: 'L1177'
    },
    /*{
      name: 'The Indic Store',
      mcc: '5973',
      mid: 'R2478'
    },*/
    {
      name: 'Raymond Group',
      mcc: '5651',
      mid: 'C1769'
    },
    {
      name: 'Center for Hearing',
      mcc: '5975',
      mid: 'H2684'
    },
    {
      name: 'National Car Rental',
      mcc: '3393',
      mid: 'C1832'
    },
    {
      name: 'Sunil Suri Associates',
      mcc: '7276',
      mid: 'T2265'
    },
    {
      name: 'Reliance Jio Infocomm',
      mcc: '4814',
      mid: 'T1766'
    },
    {
      name: 'Door to Door Marketing',
      mcc: '5963',
      mid: 'M2384'
    },
    {
      name: 'Taj Hotels International',
      mcc: '3659',
      mid: 'H2382'
    },
    {
      name: 'Sushruta Ayurvedic Clinic',
      mcc: '8011',
      mid: 'D1065'
    },
    {
      name: 'Safdarjung Medical College',
      mcc: '8062',
      mid: 'H1679'
    },
    {
      name: 'Indian Farmers Fertilizer Co-op',
      mcc: '763',
      mid: 'A1668'
    },
    {
      name: 'Construction Federation of India',
      mcc: '1799',
      mid: 'C2671'
    }
  ]
}


