import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Images } from '../app-assets.constants';
import { NavigationService } from '../navigation.service';
import { Constants } from './reversal.constants';

@Component({
  selector: 'reversal',
  templateUrl: './reversal.component.html',
  styleUrls: ['./reversal.component.scss']
})
export class ReversalComponent implements OnInit {

  constants = Constants;
  images = Images;
  
  form: FormGroup;
  submitted = false;
  response;
  apiHasBeenHit: boolean = false;
  merchantNameSelected = null;
  paymentChannelSelected = null;

  keysToDisplay = ['responseCode'];
  ParameterNameMap = this.constants.ParameterNameMap;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private navigator: NavigationService
  ) { }

  ngOnInit(): void {
    
    this.form = this.formBuilder.group(
      {
        // cardId: [
        //   '', 
        //   [
        //     Validators.required, 
        //     Validators.minLength(36),
        //     Validators.maxLength(36)
        //   ]
        // ],
        rrn: [null, Validators.required]
      }
    );

    // todo: remove this 
    // this.form.get('rrn').setValue('11');
    // this.form.get('cardId').setValue('11111111');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form.value);
      return;
    }
    this.apiHasBeenHit = true;
    console.log(JSON.stringify(this.form.value, null, 2));
    
    const body = {
      //cardId: this.form.get('cardId').value,
      rrn: this.form.get('rrn').value
    }

    this.navigator.showLoader();

    this.api.reversal(body)
    .subscribe(response => {    
      this.response = response;    
      this.navigator.hideLoader();
    }, error => {
      this.navigator.showSnackBar('Server issues, Please try again later');
      this.navigator.hideLoader();
      this.response = {};
    });
    // this.response = {
    //   "responseCode":"00",
    // };    
  }
}
