<app-loader></app-loader>
<div class="tabs">

  <mat-tab-group mat-align-tabs="start" #tabs>
    <mat-tab label="{{constants.TextLabels.PreAuthTab}}" class="tab-content">
      <pre-auth></pre-auth>
    </mat-tab>
    <mat-tab label="{{constants.TextLabels.PostAuthTab}}">
      <post-auth></post-auth>
    </mat-tab>
    <mat-tab label="{{constants.TextLabels.ReversalTab}}">
      <reversal></reversal>
    </mat-tab>
    <mat-tab label="{{constants.TextLabels.VideoKyc}}">
      <video-kyc></video-kyc>
    </mat-tab>

    <!-- <mat-tab disabled>
      <reversal></reversal>
    </mat-tab> -->
    <mat-tab disabled>
      <reversal></reversal>
    </mat-tab>

    <mat-tab disabled *ngIf="environmentButtonName">
      <ng-template mat-tab-label>

        <input type="button"
               disabled
               style="color: black; background-color: white; font: 14px Roboto; font-weight: bold; border: none; padding: 0 20px 0 20px;"
               [value]="environmentName">

        <input type="button"
               style="color: white; background-color: #000000; border: none; font: 14px Roboto; padding: 6px 12px; border-radius: 4px"
               [value]="environmentButtonName"
               (click)="changeEnvironment()">
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</div>
