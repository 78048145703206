<div class="register-form">
  <form class="form" [formGroup]="form">

    <!-- cardId -->
    <div class="form-group cardId">
      <label>User ID</label>
      <input
        type="text"
        formControlName="userId"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.userId.errors }"
      />
      <div *ngIf="submitted && f.userId.errors" class="invalid-feedback">
        <div *ngIf="f.userId.errors.required">User Id is required</div>
      </div>
    </div>

    <!-- Status -->
    <div class="form-group status">
      <label>KYC Approval</label>

      <app-dropdown 
        [title]="'kycApproval'" 
        [options]="kycApprovalOptions" 
        (currentValueChange)="kycApprovalUpdated($event)"
        [ngClass]="{ 'is-invalid': submitted && f.kycApproval.errors }"
      ></app-dropdown>

      <div *ngIf="submitted && f.kycApproval.errors" class="invalid-feedback">
        <div *ngIf="f.kycApproval.errors.required">kycApproval is required</div>
        <div *ngIf="f.kycApproval.errors.kycApproval">kycApproval is invalid</div>
      </div>
    </div>
    
    <!-- Failure Reason -->
    <div class="form-group rrn">
      <label *ngIf="!failureReasonRequired" class="disabled-label">Failure Reason</label>
      <input
        *ngIf="!failureReasonRequired"
        type="text"
        [attr.disabled]="true"
        formControlName="reason"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.reason.errors }"
      />
      <label *ngIf="failureReasonRequired">Failure Reason</label>
      <input
        *ngIf="failureReasonRequired"
        type="text"
        formControlName="reason"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.reason.errors }"
      />
      <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
        <div *ngIf="f.reason.errors.required">Reason for failure is required</div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="form-group submit">
      <label class="hidden">hidden</label>
      <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
    </div>
  </form>

  <table class="response-table" *ngIf="apiHasBeenHit && responseCode != null">
    <tr class="header">
      <th class="name">Parameter Name</th>
      <th class="value">Parameter Value</th>
    </tr>
    <tr *ngFor="let key of keysToDisplay" class="{{key}}">
      <td>Status</td>
      <ng-container>
        <td *ngIf="responseCode===200" class="success">Processed</td>
        <td *ngIf="responseCode!=200" class="failure">Facing error with the server</td>
      </ng-container>
    </tr>
  </table>

  <div *ngIf="apiHasBeenHit && !responseCode" class="no-data">
    <img [src]="images.PostAuthNoData" alt="no data avaiable">
  </div>

</div>