import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Images } from '../app-assets.constants';
import { NavigationService } from '../navigation.service';
import { Constants } from './post-auth.constants';

@Component({
  selector: 'post-auth',
  templateUrl: './post-auth.component.html',
  styleUrls: ['./post-auth.component.scss']
})
export class PostAuthComponent implements OnInit {

  constants = Constants;
  images = Images;
  
  form: FormGroup;
  submitted = false;
  response;
  apiHasBeenHit: boolean = false;
  statusSelected = null;

  keysToDisplay = ['responseCode'];
  statusOptions = this.constants.statusOptions;
  ParameterNameMap = this.constants.ParameterNameMap;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private navigator: NavigationService
  ) { }

  ngOnInit(): void {
    
    this.form = this.formBuilder.group(
      {
        // cardId: [
        //   '', 
        //   [
        //     Validators.required, 
        //     Validators.minLength(36),
        //     Validators.maxLength(36)
        //   ]
        // ],
        status: [null, Validators.required],
        rrn: [
          '',
          [
            Validators.required,
          ]
        ]
      }
    );

    // todo: remove this 
    // this.form.get('cardId').setValue('11111111');
    // this.form.get('status').setValue('11111111');
    // this.form.get('rrn').setValue('111');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form.value);
      return;
    }
    this.apiHasBeenHit = true;
    console.log(JSON.stringify(this.form.value, null, 2));
    
    const body = {
    //  cardId: this.form.get('cardId').value,
      status: this.statusSelected.value,
      rrn: this.form.get('rrn').value
    }

    this.navigator.showLoader();

    this.api.postAuth(body)
    .subscribe(response => {    
      this.response = response;
      this.navigator.hideLoader();
    }, error => {
      // this.response = {
      //   "responseCode":"00",
      // };  
      this.navigator.hideLoader();
      this.navigator.showSnackBar('Server issues, Please try again later');
      this.response = {};
    });    
  }

  statusUpdated(status) {
    this.statusSelected = status;
    this.form.get('status').setValue(status.value);
  }
}
