import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Images } from '../app-assets.constants';
import { NavigationService } from '../navigation.service';
import { Constants } from './video-kyc.constants';

@Component({
  selector: 'video-kyc',
  templateUrl: './video-kyc.component.html',
  styleUrls: ['./video-kyc.component.scss']
})
export class VideoKycComponent implements OnInit {

  constants = Constants;
  images = Images;
  
  form: FormGroup;
  submitted = false;
  responseCode = null;
  apiHasBeenHit: boolean = false;
  kycApprovalSelected = null;

  keysToDisplay = ['responseCode'];
  kycApprovalOptions = this.constants.kycApprovalOptions;
  ParameterNameMap = this.constants.ParameterNameMap;
  failureReasonRequired = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private navigator: NavigationService
  ) { }

  ngOnInit(): void {
    
    this.form = this.formBuilder.group(
      {
        userId: [
          '86e048ab-ff46-45db-af74-04cb56bc5fa6', 
          [
            Validators.required, 
            Validators.minLength(36),
            Validators.maxLength(36)
          ]
        ],
        kycApproval: [null, Validators.required],
        reason: [
          '',
          []
        ]
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form.value);
      return;
    }
    this.apiHasBeenHit = true;
    console.log(JSON.stringify(this.form.value, null, 2));
    
    const body = {
      userId: this.form.get('userId').value,
      kycApproval: this.kycApprovalSelected.value,
    }

    if (this.failureReasonRequired) 
      body['reason'] = this.form.get('reason').value;

    this.navigator.showLoader();
    // 
    this.responseCode = null;

    this.api.videoKyc(body)
    .subscribe(response => {    
      this.responseCode = response.status;
      // debugger
      this.navigator.hideLoader();
    }, error => {
      this.navigator.hideLoader();
      this.navigator.showSnackBar('Server issues, Please try again later');
      this.responseCode = 400;
    });    
  }

  kycApprovalUpdated(kycApproval) {
    this.kycApprovalSelected = kycApproval;
    this.updateFailureReasonRequirement();
    this.form.get('kycApproval').setValue(kycApproval.value);
  }

  updateFailureReasonRequirement() {
    if (['SUCCESS', 'PENDING'].includes(this.kycApprovalSelected.value)) {
      debugger
      this.failureReasonRequired = false;
      this.form.controls['reason'].setValidators([]);
    } else {
      debugger
      this.failureReasonRequired = true;
      this.form.controls['reason'].setValidators([Validators.required]);
    }
  }
}
