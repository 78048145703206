<div class="register-form" id="main">
  <form class="form" [formGroup]="form">

    <!-- cardId -->
    <div class="form-group cardId">
      <label>VPAN / Card Id</label>
      <input
        type="text"
        formControlName="cardId"
        class="form-control"
        (input)="updateValue($event)"
        [ngClass]="{ 'is-invalid': submitted && f.cardId.errors }"
      />
      <div *ngIf="submitted && f.cardId.errors" class="invalid-feedback">
        <div *ngIf="form.get('cardId').value.length === 0">
          <div>Card Id / VPAN is required</div>
        </div>
        <div *ngIf="form.get('cardId').value.length <= 16 && form.get('cardId').value.length > 0">
          VPAN should be 16 characters long
        </div>
        <div *ngIf="form.get('cardId').value.length > 16">
          <div *ngIf="f.cardId.errors.minlength || f.cardId.errors.maxlength">Card Id should be 36 characters long</div>
        </div>
      </div>
    </div>

    <!-- merchantName -->
    <div class="form-group merchantName">
      <label (click)="showMerchantDetails(true)" style="cursor: pointer">Merchant Name</label>
      <app-dropdown
        [title]="'Merchant Name'"
        [ngClass]="{ 'is-invalid': submitted && f.merchantName.errors }"
        [options]="merchantNameOptions"
        (currentValueChange)="merchantNameUpdated($event)"
      ></app-dropdown>
      <div *ngIf="submitted && f.merchantName.errors" class="invalid-feedback">
        <div *ngIf="f.merchantName.errors.required">Merchant Name is required</div>
        <div *ngIf="f.merchantName.errors.merchantName">Merchant Name is invalid</div>
      </div>
    </div>

    <!-- paymentChannel -->
    <div class="form-group paymentChannel">
      <label>Payment Channel</label>
      <app-dropdown
        [title]="'Payment Channel'"
        [ngClass]="{ 'is-invalid': submitted && f.paymentChannel.errors }"
        [options]="paymentChannelOptions"
        (currentValueChange)="paymentChannelUpdated($event)"
      ></app-dropdown>

      <div *ngIf="submitted && f.paymentChannel.errors" class="invalid-feedback">
        <div *ngIf="f.paymentChannel.errors.required">Payment Channel is required</div>
        <div *ngIf="f.paymentChannel.errors.paymentChannel">Payment Channel is invalid</div>
      </div>
    </div>

    <!-- Amount -->
    <div class="form-group amount">
      <label>Amount</label>
      <input
        type="number"
        formControlName="amount"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
      />
      <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
        <div *ngIf="f.amount.errors.required">Amount is required</div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="form-group submit">
      <label class="hidden">hidden</label>
      <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
    </div>
  </form>

  <table class="response-table" *ngIf="apiHasBeenHit && response && response['rrn']">
    <tr class="header">
      <th class="name">Parameter Name</th>
      <th class="value">Parameter Value</th>
    </tr>
      <tr *ngFor="let key of keysToDisplay" class="{{key}}">
        <td>{{ParameterNameMap[key]}}</td>
        <td *ngIf="key!='status'">{{response[key]}}</td>
        <ng-container *ngIf="key==='status'">
          <td *ngIf="response[key]==='00'" class="success">Success</td>
          <td *ngIf="response[key]==='05'" class="failure">Failure</td>
        </ng-container>

      </tr>
  </table>

</div>

<div id="aux" style="display: none">
  <table style="width: 30%">
    <tr>
      <th>Name</th>
      <th>MCC</th>
      <th>MID</th>
    </tr>
    <tbody id="root"></tbody>
    <tr><td colspan="3" align="center">
      <input style="background-color: white; border: none; color: darkblue"
             type="button" value="Back" (click)="showMerchantDetails(false)">
    </td></tr>
  </table>

</div>
