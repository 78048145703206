import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {Router} from '@angular/router';
import {Constants} from './app.constants';
import {ApiService} from './api.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  environment: string = null;
  public environmentName: string = null;
  public environmentButtonName: string = null;
  constants = Constants;
  @ViewChild('tabs', {static: false}) tabGroup: MatTabGroup;

  constructor(
    private router: Router,
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: any
  ) {
    const domain = this.document.location.hostname;
    console.log('dfd',domain)
    this.environment = this.findEnvironment(domain);
    console.log('dd',this.environment)
    this.environmentName = this.getEnvironmentName();
    this.environmentButtonName = this.getEnvironmentButtonName();
    if(domain === 'localhost'){
      this.apiService.API_URL = 'http://dev.tab.kitecash.in/simulator/fis/transaction'; 
    }else{
    this.apiService.API_URL = 'https://' + this.environment + '.tab.kitecash.in/simulator/fis/transaction';
    }
    /*switch (environment.toLowerCase()) {
      case 'dev':
        this.apiService.API_URL = 'https://dev.tab.kitecash.in/simulator/fis/transaction';
        break;
      case 'qa':
        this.apiService.API_URL = 'https://qa.tab.kitecash.in/simulator/fis/transaction';
        break;
      case 'stage':
        this.apiService.API_URL = 'https://stage.tab.kitecash.in/simulator/fis/transaction';
        break;
      case 'stage1':
        this.apiService.API_URL = 'https://stage1.tab.kitecash.in/simulator/fis/transaction';
        break;
    }*/
  }

  ngAfterViewInit() {
    this.tabGroup.selectedIndex = 0;
  }

  findEnvironment(domain: string) {
    console.log('domain',domain)
    const indexOfFirstDot = domain.indexOf('.');
    if (indexOfFirstDot === -1) {
      return null;
    }

    domain = domain.slice(indexOfFirstDot + 1, domain.length);

    const indexOfSecondDot = domain.indexOf('.');
    if (indexOfSecondDot === -1) {
      return null;
    }

    return domain.slice(0, indexOfSecondDot);
  }

  private getEnvironmentName(): string {
    if (!this.environment) {
      return 'Localhost';
    }
    if (this.environment === 'stage') {
      return 'UAT';
    }
    if (this.environment === 'stage1') {
      return 'Simulator';
    }
    return null;
  }

  private getEnvironmentButtonName(): string {
    if (!this.environment) {
      return 'Reload';
    }
    if (this.environment === 'stage') {
      return 'Switch to Simulator';
    }
    if (this.environment === 'stage1') {
      return 'Switch to UAT';
    }
    return null;
  }

  changeEnvironment() {
    if (!this.environment) {
      location.href = location.href.replace('localhost', 'localhost');
    }
    if (this.environment === 'stage') {
      location.href = location.href.replace('stage', 'stage1');
    }
    if (this.environment === 'stage1') {
      location.href = location.href.replace('stage1', 'stage');
    }
  }
}
