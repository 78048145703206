export const Constants = {

  ParameterNameMap: {
    "responseCode": "Response Code",
  },

  statusOptions: [
    {
      name: '',
      value: null
    },
    {
      name: "Success",
      value: "SUCCESS"
    },
    {
      name: "Failure",
      value: "FAILURE"
    }
  ],
}