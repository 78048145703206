import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Images } from '../app-assets.constants';
import { NavigationService } from '../navigation.service';
import { Constants } from './pre-auth.constants';

@Component({
  selector: 'pre-auth',
  templateUrl: './pre-auth.component.html',
  styleUrls: ['./pre-auth.component.scss']
})
export class PreAuthComponent implements OnInit {

  constants = Constants;
  images = Images;

  form: FormGroup;
  submitted = false;
  response;
  apiHasBeenHit: boolean = false;
  merchantNameSelected = null;
  paymentChannelSelected = null;

  keysToDisplay = ['status', 'rrn'];
  paymentChannelOptions = this.constants.paymentChannelOptions;
  merchantNameOptions = this.constants.merchantNameOptions;
  ParameterNameMap = this.constants.ParameterNameMap;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private navigator: NavigationService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        cardId: [
          '',
          [
            Validators.required,
            Validators.minLength(36),
            Validators.maxLength(36)
          ]
        ],
        merchantName: [null, Validators.required],
        paymentChannel: [null, Validators.required],
        amount: [
          '',
          [
            Validators.required,
          ]
        ]
      }
    );

    // todo: remove this
    // this.form.get('paymentChannel').setValue('11');
    // this.form.get('merchantName').setValue('111');
    // this.form.get('cardId').setValue('11111111');
    // this.form.get('amount').setValue('111');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form.value);
      return;
    }
    this.apiHasBeenHit = true;
    console.log(JSON.stringify(this.form.value, null, 2));
    let requestBody;
    if(this.form.get('cardId').value.length === 16){
      requestBody = {
      vpan: this.form.get('cardId').value,
      merchantName: this.merchantNameSelected['name'],
      mcc: this.merchantNameSelected['mcc'],
      merchantId: this.merchantNameSelected['mid'],
      paymentChannel: this.paymentChannelSelected['name'],
      amount: this.form.get('amount').value
    }
  }else{
    requestBody = {
      cardId: this.form.get('cardId').value,
      merchantName: this.merchantNameSelected['name'],
      mcc: this.merchantNameSelected['mcc'],
      merchantId: this.merchantNameSelected['mid'],
      paymentChannel: this.paymentChannelSelected['name'],
      amount: this.form.get('amount').value
    }
  }
    this.navigator.showLoader();

    this.api.preAuth(requestBody)
    .subscribe(response => {
      this.response = response;
      this.navigator.hideLoader();
    }, error => {
      this.response = {};
      this.navigator.showSnackBar('Server issues, Please try again later');
      this.navigator.hideLoader();
    });
    // this.response = {
    //   "status":"05",
    //   "rrn":"223454043123"
    // };
  }

  paymentChannelUpdated(channel) {
    this.paymentChannelSelected = channel;
    this.form.get('paymentChannel').setValue(channel.value);
  }

  merchantNameUpdated(merchant) {
    this.merchantNameSelected = merchant;
    this.form.get('merchantName').setValue(merchant.name);
  }

  private populateMerchants(show: boolean) {
    const tbody = document.getElementById('root') as HTMLElement;

    if (!show) {
      tbody.innerText = '';
      return;
    }

    let i = 0;

    this.merchantNameOptions.forEach(merchant => {
      const tdName = document.createElement('td');
      tdName.innerText = merchant.name;
      const tdMcc = document.createElement('td');
      tdMcc.innerText = merchant.mcc;
      const tdMid = document.createElement('td');
      tdMid.innerText = merchant.mid;

      const tr = document.createElement('tr');
      tr.appendChild(tdName);
      tr.appendChild(tdMcc);
      tr.appendChild(tdMid);

      tr.style.backgroundColor = i%2 === 0 ? '#eeeeee' : '#ffffff';

      tbody.appendChild(tr);

      i++;
    });
  }

  showMerchantDetails(show: boolean) {
    document.getElementById('main').style.display = show ? 'none' : 'block';
    document.getElementById('aux').style.display = show ? 'block' : 'none';
    this.populateMerchants(show);
  }

  updateValue(event) {
    console.log('value',event.target.value)
      const minLengthValidator = Validators.minLength(36);
      const maxLengthValidator = Validators.maxLength(36);
      if(event.target.value.length === 16){  
      this.form.get('cardId').clearValidators();
      this.form.get('cardId').setValue(event.target.value); 
      }else{
        this.form.get('cardId').setValidators([minLengthValidator,maxLengthValidator]);
        this.form.get('cardId').setValue(event.target.value); 
      }
        this.form.get('cardId').updateValueAndValidity();
  }
}
